import { Component } from '@angular/core';

@Component({
  selector: 'app-about-component',
  templateUrl: './about-component.html',
  styleUrls: ['./about-component.css'],
})
export class AboutComponent {
  constructor() {}
}
