<div class="container">
  <div class="footer-container">
    <div class="footer-middle-container">
      <div class="footer">
        <div class="flex-container">
          <h2>{{'contacts' | translate}}</h2>
          <div class="flex">
            <div class="half-width">
              <div>
                <br />
                <span>
                  <span>{{'mobile' | translate}}:</span>
                  <p class="break">
                    <a href="tel:+49015204077719">(+49) 015204077719</a>
                  </p>
                </span>
                <span>
                  <span>Email:</span>
                  <p class="break">
                    <a href="mailto:info@lages-fugentechnik.de"
                      >info&#64;lages-fugentechnik.de</a
                    >
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <br *ngIf="windowRes" />
        <mat-divider *ngIf="windowRes"></mat-divider>
        <br *ngIf="windowRes" />
        <div class="flex-container" [ngClass]="{'middle': !windowRes}">
          <div>
            <h2>{{'follow' | translate}}</h2>
            <div class="half-width">
              <br />
              <div class="icons">
                <fa-icon
                  [icon]="insta"
                  size="2x"
                  class="instagram"
                  (click)="openPage('ig')"
                ></fa-icon>
                <fa-icon
                  class="margin-left"
                  [icon]="fb"
                  size="2x"
                  style="color: #1877f2"
                  class="facebook"
                  (click)="openPage('fb')"
                ></fa-icon>
              </div>
            </div>
          </div>
        </div>
        <br *ngIf="windowRes" />
        <mat-divider *ngIf="windowRes"></mat-divider>
        <br *ngIf="windowRes" />
        <div class="flex-container" [ngClass]="{'end': !windowRes}">
          <h2>{{'impressum.privacy_policy' | translate}}</h2>
          <br />
          <div>
            <a routerLink="/impressum">{{'impressum_single' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="middle">© Lages Fugentechnik 2024</div>
    <br />
  </div>
</div>
