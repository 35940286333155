/* eslint-disable @typescript-eslint/no-empty-function */
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaintenanceComponent } from '../components/ui-module/maintenence-component/maintenance.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiModule } from '../components/ui-module/ui-module';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED } from '@angular/common';

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
): any {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        const langToSet = getCookieValue(document.cookie);
        const hasCookie = langToSet.get('language') || 'de';
        translate.setDefaultLang(hasCookie);
        translate.use(hasCookie).subscribe(
          () => {},
          (err) => {},
          () => {
            resolve(null);
          },
        );
      });
    });
}

export function getCookieValue(a): any {
  const cookie = a
    .split(';')
    .map((ss) => ss.split('=').map((c) => c.trim()))
    .reduce((m, [k, v]) => (m.set(k, v), m), new Map());
  return cookie;
}

@NgModule({
  declarations: [AppComponent, MaintenanceComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UiModule,
    FontAwesomeModule,
    HttpClientModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatIconModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}
