<style>
  .mail-margin {
    margin-top: 2rem;
  }

  .background-image {
    background: url("../../../assets/logo_a.png") no-repeat;
    background-size: contain;
    opacity: 0.08;
    position: absolute;
    top: 90px;
    left: 0;
    height: 47%;
    width: 100%;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .text-container {
    text-align: justify;
  }

  .select-language {
    cursor: pointer;
    width: 30px;
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .select-language:hover {
    text-decoration: underline;
    text-decoration-color: #333333;
  }

  .title-header {
    text-align: center;
    white-space: nowrap;
  }

  .padding {
    padding: 100px;
    position: relative;
    min-height: 250px;
    top: 60px;
  }

  .navigation-bar a {
    margin-left: 10px;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 820px) {
    .background-image {
      background: url("../../../assets/logo_a.png") no-repeat;
      background-size: contain;
      opacity: 0.5;
      position: absolute;
      top: 21px;
      left: 0;
      height: 47%;
      width: 95%;
      z-index: 0;
    }

    .padding-top {
      padding-top: 130px;
      position: relative;
    }

    .content {
      margin-top: 10rem;
    }

    .select-language {
      width: 50px;
      position: relative;
      display: inline-block;
      text-align: center;
    }

    .container {
      display: block;
      text-align: center;
    }
  }

</style>

<div class="container-middle padding">
  <div class="background-image"></div>

  <!-- Body -->

  <div class="container">
    <h1 class="title-header">
      {{ 'maintenance.under_maintenance' | translate }}
    </h1>
    <br>
    <div class="subtitle-header text-container">
      {{ 'maintenance.sorry_for_inconv' | translate }}
    </div>
    <div class="mail-margin">
      <span>
        {{ 'maintenance.please_use_our_email' | translate }}
      </span>
      <br>
      <div>
        <br>
        <a href="mailto:info@lages-fugentechnik.de">info&#64;lages-fugentechnik.de</a>
        <br>
        <a href="tel:+49015204077719">(+49) 015204077719</a>
      </div>
    </div>
    <br>
    <div>
      - Christophe Lages
    </div>
  </div>
</div>

