import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Lages Fugentechnik';
  selectedLanguage: any;
  fetchCookie = this.getCookieValue(document.cookie);
  fetchCookieLanguage = this.fetchCookie.get('language') || 'de';
  resolutionToggle = 820;
  currentPath: string;

  constructor(
    public router: Router,
    public translate: TranslateService,
  ) {
    translate.setDefaultLang(this.fetchCookieLanguage);
  }
  public hasScroll: any;
  public toggleLanguages: any;
  public screenWidth: any;
  public screenHeight: any;
  public isToggable: boolean;
  public isDropdownVisible: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isToggable = window.innerWidth > this.resolutionToggle;
    if (window.innerWidth > this.resolutionToggle && this.isToggable) {
      this.isDropdownVisible = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.hasScroll = window.scrollY > 10;
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isToggable = window.innerWidth > this.resolutionToggle;
    this.toggleLanguages =
      this.fetchCookieLanguage || this.translate.getDefaultLang();
    setTimeout(() => {
      this.fetchActiveTab();
    }, 0);
  }

  fetchActiveTab(): void {
    const tabs = document.getElementsByClassName('router-links');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].classList.contains('active')) {
        this.currentPath = tabs[i].innerHTML.trim();
      }
    }
  }

  updateCookie(language: string): void {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.toggleLanguages = language;
  }

  clickDropdown(): void {
    if (window.innerWidth < this.resolutionToggle) {
      this.isDropdownVisible = !this.isDropdownVisible;
    }
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.fetchActiveTab();
    }, 0);
  }

  switchLanguage(lang: string): void {
    this.toggleLanguages = lang;
    this.translate.use(lang);
    document.cookie = 'language=' + lang;
    if (this.isDropdownVisible) {
      setTimeout(() => {
        this.clickDropdown();
      }, 0);
    }
  }

  getCookieValue(a): any {
    const cookie = a
      .split(';')
      .map((ss) => ss.split('=').map((c) => c.trim()))
      .reduce((m, [k, v]) => (m.set(k, v), m), new Map());
    return cookie;
  }
}
