<section class="home-container">
  <div class="banner-container container-middle">
    <div class="container-left">
      <h1 class="left-title">{{ 'banner.about_us' | translate }}</h1>
      <div class="left-description">
        {{ 'banner.description1' | translate }}
      </div>
      <br>
      <div class="left-description">
        {{ 'banner.description2' | translate }}
      </div>
    </div>
    <span class="separator"></span>
    <div class="container-right">
      <img class="image-container" src="../../../assets/IMG_20200915_124047.jpg">
    </div>
  </div>
</section>
