<style>
  :host {
    font-size: 18px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  .content {
    display: flex;
    padding: 0 16px;
    margin-top: 7rem;
    flex-direction: column;
    align-items: center;
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  footer a {
    display: flex;
    align-items: center;
  }

  .navigation-bar {
    display: flex;
    flex-direction: row;
    background-color: #ffffffb0;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid #d3d3d326;
    transition: 1s ease;
  }

  .white {
    transition: 1s ease;
    background-color: #fff;
  }

  .container-flex {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .navigation-links {
    display: none;
    flex-direction: row;
    flex: 1 0 auto;
  }

  .navigation-links.visible {
    display: flex;
  }

  .logo-navbar {
    margin: 0.5rem;
    background-image: url("../assets/logo_a.png");
    height: 53px;
  }

  .navigation-links a {
    margin: 1.5rem;
  }

  .router-links {
    color: inherit;
    background: transparent;
    user-select: none;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    visibility: hidden;
    border: none;
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    border: 1px solid #d3d3d326;
  }

  .router-links.visible {
    visibility: visible;
  }

  .identifier {
    align-self: center;
    cursor: default;
  }

  .router-links-custom {
    color: inherit;
    background: transparent;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    border: 1px solid #d3d3d326;
  }

  .router-links:hover {
    color: inherit;
    background: #e3e3e3;
  }

  .router-links-custom:hover {
    color: inherit;
    background: lightgrey;
  }

  .router-links.active {
    color: inherit;
    background: #f1f1f1;
  }

  .router-links-custom.active {
    color: inherit;
    background: #eaeaea;
  }

  .router-links.active:hover {
    color: inherit;
    background: lightgrey;
  }

  .router-links-custom.active:hover {
    color: inherit;
    background: lightgrey;
  }

  .language-container {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  @media screen and (max-width: 350px) {
    .logo-navbar {
      margin: auto;
      height: 38px;
      padding-right: 15px;
    }
  }
  /* Responsive Styles */
  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 820px) {

    .navigation-bar {
      flex-direction: column;
    }

    .language-container {
      right: 84px;
    }

    .navigation-links {
      flex-direction: column;
    }

    .menu-css {
      position: relative;
      top: 7px;
    }

    .content {
      margin-top: 10rem;
    }

    .toggable {
      height: 70px;
    }

    .flex-container {
      flex: 1;
      flex-direction: row;
      text-align: right;
    }

    .language-container {
      position: relative;
      display: flex;
      flex-direction: column;
      right: 0;
      top: 0;
    }

  }

</style>
<nav class="navigation-bar" [ngClass]="{'white': hasScroll}">
  <div class="flex container-flex">
    <img class="logo-navbar" src="../assets/logo_a.png">
    <div class="router-links visible identifier" *ngIf="!isToggable"
         [ngClass]="{'dropdown': isToggable}">{{ currentPath | translate }}
    </div>
    <div class="flex-container">
      <button *ngIf="!isToggable" (click)="clickDropdown()" class="router-links visible toggable">
        <mat-icon class="menu-css">menu</mat-icon>
      </button>
    </div>
  </div>
  <div class="navigation-links" [ngClass]="{'visible': isDropdownVisible || isToggable}">
    <button routerLink="/" class="router-links"
            (click)="clickDropdown()"
            [ngClass]="{'active': router.url === '/', 'dropdown': isToggable, 'visible': isDropdownVisible || isToggable}">{{ 'home' | translate }}
    </button>
<!--    <br *ngIf="isToggable">-->
<!--    <button routerLink="/services" class="router-links"-->
<!--            (click)="clickDropdown()"-->
<!--            [ngClass]="{'active': router.url === '/services', 'dropdown': isToggable, 'visible': isDropdownVisible || isToggable}">{{ 'services' | translate }}-->
<!--    </button>-->
    <br *ngIf="isToggable">
    <button routerLink="/about" class="router-links"
            (click)="clickDropdown()"
            [ngClass]="{'active': router.url === '/about', 'dropdown': isToggable, 'visible': isDropdownVisible || isToggable}">{{ 'about' | translate }}
    </button>
    <br *ngIf="isToggable">
    <button routerLink="/contacts" class="router-links"
            (click)="clickDropdown()"
            [ngClass]="{'active': router.url === '/contacts', 'dropdown': isToggable, 'visible': isDropdownVisible || isToggable}">{{ 'contacts' | translate }}
    </button>
    <br *ngIf="isToggable">

    <div class="language-container">
      <br *ngIf="isDropdownVisible">
      <button class="router-links-custom" (click)="switchLanguage('de')" *ngIf="isDropdownVisible || isToggable"
              [ngClass]="{'active': this.toggleLanguages === 'de', 'dropdown': isToggable, 'visible': isDropdownVisible || isToggable}">
        DE <span class="flag-icon flag-icon-de flag-icon-squared"></span>
      </button>
      <button class="router-links-custom" (click)="switchLanguage('en')" *ngIf="isDropdownVisible || isToggable"
              [ngClass]="{'active': this.toggleLanguages === 'en', 'dropdown': isToggable, 'visible': isDropdownVisible || isToggable}">
        ENG <span class="flag-icon flag-icon-gb flag-icon-squared"></span>
      </button>
    </div>
  </div>
</nav>
<div class="content-margin">
  <router-outlet></router-outlet>
</div>
<app-footer-component></app-footer-component>
<app-cookie-component (cookie)="updateCookie($event)" ></app-cookie-component>
