import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about-component/about-component';
import { ContactsComponent } from './contacts-component/contacts-component';
import { HomeComponent } from './home-component/home-component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FooterComponent } from './footer-component/footer-component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { CookieComponent } from './cookie-component/cookie-component';
import { ImpressumComponent } from './impressum-component/impressum-component';
import { RouterModule, Routes } from '@angular/router';
import { GaleryComponent } from './galery-component/galery-component';

const routes: Routes = [{ path: 'impressum', component: ImpressumComponent }];

@NgModule({
  declarations: [
    AboutComponent,
    ContactsComponent,
    HomeComponent,
    FooterComponent,
    CookieComponent,
    ImpressumComponent,
    GaleryComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule.forRoot(routes),
  ],
  providers: [MatSnackBar, Overlay, FormGroupDirective],
  bootstrap: [],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FooterComponent,
    CookieComponent,
  ],
})
export class UiModule {}
