<div class="cookie-policy-banner" *ngIf="!hasCookieSet">
  <div class="cookie-container">
    <div class="cookie-banner">
      <div>
        <div class="language-container">
          <br>
          <button class="language-link" (click)="switchLanguage('de')"
                  [ngClass]="{'active': this.setLanguage === 'de'}">
            <span class="flag-icon flag-icon-de flag-icon-squared"></span>
            DE
          </button>
          <button class="language-link" (click)="switchLanguage('en')"
                  [ngClass]="{'active': this.setLanguage === 'en'}">
            <span class="flag-icon flag-icon-gb flag-icon-squared"></span>
            ENG
          </button>
        </div>
        <br>
        <div class="cookie-div">{{'accept_cookies' | translate}}</div>
        <div class="cookie-div">{{'cookie_description' | translate}}</div>
        <br>
        <div class="cookie-div flex">
          <button class="button-cookies" (click)="acceptCookie()">Accept</button>
        </div>
      </div>
    </div>
  </div>
</div>
