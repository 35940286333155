import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime } from 'rxjs/operators';
import { constants } from '../../../constants/constants.js';

@Component({
  selector: 'app-contacts-component',
  templateUrl: './contacts-component.html',
  styleUrls: ['./contacts-component.css'],
})
export class ContactsComponent implements OnInit {
  public publicForm: FormGroup;
  public isValid = false;

  constructor(
    public fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.publicForm = new FormGroup({
      name: new FormControl(
        [''],
        [Validators.required, Validators.minLength(1)],
      ),
      subject: new FormControl(
        [''],
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(constants.message.maxSubjectBodySize),
        ],
      ),
      message: new FormControl(
        [''],
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(constants.message.maxMessageBodySize),
        ],
      ),
    });

    this.publicForm.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      this.isFormValid();
    });
  }

  arrowRight = faArrowRight;
  formSubmitted = false;

  ngOnInit(): void {
    this.resetForm();
  }

  getErrorMessage(): string {
    const form = this.publicForm;
    return form.get('email')?.hasError('required')
      ? 'You must enter a value'
      : form.get('email')?.hasError('email')
        ? 'Not a valid email'
        : '';
  }

  isFormValid(): void {
    const form = this.publicForm;
    const hasErrors = !(
      !form.get('name')?.errors?.required &&
      !form.get('name').pristine &&
      !form.get('subject')?.errors?.required &&
      !form.get('subject').pristine &&
      !form.get('message')?.errors?.required &&
      !form.get('message').pristine
    );

    if (!hasErrors) {
      this.formSubmitted = false;
    }

    setTimeout(() => {
      this.isValid = !hasErrors;
    });
  }

  submit(): void {
    this.isFormValid();
    const form = this.publicForm;
    if (this.isValid && !this.formSubmitted) {
      this.formSubmitted = true;
      window.open(
        'mailto:info@lages-fugentechnik.de?subject=' +
          encodeURIComponent(form.get('subject').value) +
          '&body=' +
          encodeURIComponent(form.get('message').value),
      );
      // this.openSnackBar('Email called successfully');
    }
  }

  // openSnackBar(message: string): void {
  //   this.snackBar.open(message, '', {
  //     duration: 5000,
  //     horizontalPosition: 'right',
  //     verticalPosition: 'top',
  //     panelClass: ['green-snackbar']
  //   });
  // }

  resetForm(): void {
    this.publicForm.clearValidators();
    this.publicForm.reset('subject');
    this.publicForm.reset('message');
  }
}
