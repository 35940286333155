<div class="iframe-container">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d590.2450955365144!2d10.413155544604534!3d53.718611862616015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b21c78056521c7%3A0xd842ac0ed019bce9!2sParadies%203%2C%2022964%20Steinburg%2C%20Alemanha!5e0!3m2!1spt-PT!2spt!4v1621710712512!5m2!1spt-PT!2spt"
    class="iframe-content" width="50%" height="450" frameborder="1" style="border:1px solid lightgrey;"
    allowfullscreen="" aria-hidden="false"
    tabindex="0"></iframe>
  <div class="about-us-container">
    <div class="container">
      <div class="section-header">
        <h1>{{ "contact_page.contact_title" | translate }}</h1>
        <p>{{ "contacts_description" | translate }}</p>
      </div>
      <form class="form" (ngSubmit)="submit()" [formGroup]="publicForm">
        <div class="form-container">
          <div class="form-subsection">
            <mat-form-field class="extended-field full-width">
              <input matInput type="text" formControlName="name" placeholder="{{'name' | translate}}" required>
              <mat-error
                *ngIf="publicForm.controls['name'].errors && publicForm.controls['name'].touched">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-container">
          <div class="form-subsection">
            <mat-form-field class="extended-field full-width">
              <input matInput type="text" formControlName="subject" placeholder="{{'subject' | translate}}" required>
              <mat-error
                *ngIf="publicForm.controls['subject'].errors && publicForm.controls['subject'].touched">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-subsection">
            <mat-form-field class="extended-field full-width">
              <input matInput type="text" formControlName="message" placeholder="{{'message' | translate}}" required>
              <mat-error
                *ngIf="publicForm.controls['message'].errors && publicForm.controls['message'].touched">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-container button">
          <button type="submit"
                  [ngClass]="{'mat-button-disabled': !isValid}"
                  class="mat-focus-indicator mat-fab mat-button-base mat-primary round">
            <span class="mat-icon-wrapper">
              <fa-icon [icon]="arrowRight" size="2x" class="arrow-right"></fa-icon>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
