import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookie-component',
  templateUrl: './cookie-component.html',
  styleUrls: ['./cookie-component.css'],
})
export class CookieComponent implements OnInit {
  public hasCookieSet: any;
  private fetchBody: any;
  private languageButton: any;
  private setLanguage: string;

  constructor(
    public translate: TranslateService,
    private router: Router,
  ) {
    this.hasCookieSet = document.cookie;
    this.fetchBody = document.getElementsByTagName('body');
    this.languageButton = document.getElementsByClassName('language-container');
    this.setLanguage = translate.getDefaultLang();
  }

  @Output()
  cookie: EventEmitter<string> = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.hasCookieSet && navigator.cookieEnabled) {
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 0);
      this.fetchBody[0].style.overflow = 'hidden';
      this.languageButton[0].style.visibility = 'hidden';
    } else {
      const fetchCookie = this.getCookieValue(document.cookie);
      const fetchCookieLanguage = fetchCookie.get('language');
      this.translate.setDefaultLang(fetchCookieLanguage);
    }
  }

  acceptCookie(): void {
    document.cookie = 'language=' + this.setLanguage;
    this.hasCookieSet = document.cookie;
    this.fetchBody[0].style.overflow = 'initial';
    this.languageButton[0].style.visibility = 'visible';
    window.scrollTo(0, 0);
    this.cookie.emit(this.setLanguage);
  }

  switchLanguage(lang: string): void {
    this.setLanguage = lang;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  getCookieValue(a): any {
    const cookie = a
      .split(';')
      .map((ss) => ss.split('=').map((c) => c.trim()))
      .reduce((m, [k, v]) => (m.set(k, v), m), new Map());
    return cookie;
  }
}
