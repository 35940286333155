import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impressum-component',
  templateUrl: './impressum-component.html',
  styleUrls: ['./impressum-component.css'],
})
export class ImpressumComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
}
