<div class="impressum">
  <h1>{{'impressum_single' | translate}}</h1>
  <br />
  <p>Lages Fugentechnik</p>
  <p>Paradies 3, 22964 Steinburg</p>
  <br />
  <p>{{'impressum.nif' | translate}}</p>
  <br />
  <p>{{'contacts' | translate}}</p>
  <p>{{'mobile' | translate}}: +49 (01520) 40 777 19</p>
  <p>E-Mail: info&#64;lages-fugentechnik.de</p>
  <br />
  <p>{{'impressum.haftungshinweise' | translate}}</p>
  <p>{{'impressum.content' | translate }}</p>
  <div>{{'impressum.content1' | translate }}</div>
  <br />
  <p>{{'impressum.links' | translate}}</p>
  <div>{{'impressum.content2' | translate}}</div>
  <br />
  <p>{{'impressum.privacy_policy' | translate}}</p>
  <br />
  <p>{{'impressum.privacy_policy' | translate}}</p>
  <p>{{'impressum.1' | translate}}</p>
  <br />
  <p>{{'impressum.general' | translate}}</p>
  <br />
  <p>{{'impressum.following' | translate}}</p>
  <br />
  <p>{{'impressum.data' | translate}}</p>
  <br />
  <p>{{'impressum.data1' | translate}}</p>
  <br />
  <p>{{'impressum.data2' | translate}}</p>
  <p>{{'impressum.data3' | translate}}</p>
  <br />
  <p>{{'impressum.data4' | translate}}</p>
  <p>{{'impressum.data5' | translate}}</p>
  <br />
  <p>{{'impressum.data6' | translate}}</p>
  <p>{{'impressum.data7' | translate}}</p>
  <br />
  <p>{{'impressum.data8' | translate}}</p>
  <p>{{'impressum.data9' | translate}}</p>
  <br />
  <p>{{'impressum.2' | translate}}</p>
  <br />
  <p>{{'impressum.privacy' | translate}}</p>
  <br />
  <p>{{'impressum.data10' | translate}}</p>
  <br />
  <p>{{'impressum.respons' | translate}}</p>
  <p>Lages Fugentechnik</p>
  <p>Christophe Lages</p>
  <p>Paradies 3,</p>
  <p>22964 Steinburg</p>
  <p>E-Mail: info&#64;lages-fugentechnik.de</p>
  <br />
  <p>{{'impressum.data11' | translate}}</p>
  <br />
  <p>{{'impressum.data12' | translate}}</p>
  <br />
  <p>{{'impressum.data13' | translate}}</p>
  <br />
  <p>{{'impressum.data14' | translate}}</p>
  <br />
  <p>{{'impressum.data15' | translate}}</p>
  <br />
  <p>{{'impressum.data16' | translate}}</p>
  <br />
  <p>{{'impressum.data17' | translate}}</p>
  <br />
  <p>{{'impressum.data18' | translate}}</p>
  <br />
  <p>{{'impressum.data19' | translate}}</p>
  <br />
  <p>{{'impressum.data20' | translate}}</p>
  <br />
  <p>{{'impressum.data21' | translate}}</p>
  <br />
  <p>{{'impressum.data22' | translate}}</p>
  <br />
  <p>{{'impressum.data23' | translate}}</p>
  <br />
  <p>{{'impressum.data24' | translate}}</p>
  <br />
  <p>{{'impressum.data25' | translate}}</p>
  <br />
  <p>- {{'impressum.browser' | translate}}</p>
  <p>- {{'impressum.operative' | translate}}</p>
  <p>- {{'impressum.url' | translate}}</p>
  <p>- {{'impressum.host' | translate}}</p>
  <p>- {{'impressum.time' | translate}}</p>
  <p>- {{'impressum.ip' | translate}}</p>
  <br />
  <p>{{'impressum.gdpr' | translate}}</p>
  <br />
  <p>{{'impressum.form' | translate}}</p>
  <br />
  <p>{{'impressum.data26' | translate}}</p>
  <br />
  <p>{{'impressum.processing' | translate}}</p>
  <br />
  <p>{{'impressum.data27' | translate}}</p>
  <br />
</div>
