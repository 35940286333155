import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactsComponent } from '../components/ui-module/contacts-component/contacts-component';
import { HomeComponent } from '../components/ui-module/home-component/home-component';
import { ImpressumComponent } from '../components/ui-module/impressum-component/impressum-component';
import { AboutComponent } from '../components/ui-module/about-component/about-component';
import { GaleryComponent } from '../components/ui-module/galery-component/galery-component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'about', component: AboutComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'galery', component: GaleryComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
