import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';

class Orientation {}

@Component({
  selector: 'app-home-component',
  templateUrl: './home-component.html',
  styleUrls: ['./home-component.css'],
})
export class HomeComponent {
  constructor(private translate: TranslateService) {}

  cards = [
    {
      title: 'card.card1_title',
      imageUrl: '../../../assets/wc.png',
      description: 'card.card1_description',
    },
    {
      title: 'card.card2_title',
      imageUrl: '../../../assets/window.png',
      description: 'card.card2_description',
    },
    {
      title: 'card.card3_title',
      imageUrl: '../../../assets/samples.png',
      description: 'card.card3_description',
    },
  ];

  items = [
    { imageUrl: '../../../assets/produtos/logo4.png' },
    { imageUrl: '../../../assets/produtos/logo2.jpg' },
    { imageUrl: '../../../assets/produtos/logo3.png' },
    { imageUrl: '../../../assets/produtos/logo1.jpg' },
    { imageUrl: '../../../assets/produtos/logo5.png' },
  ];

  public showContent = false;

  public parentHeight = '500px';
  public timings = '250ms ease-in';
  public autoplay = true;
  public interval = 5000;
  public loop = true;
  public hideArrows = false;
  public hideIndicators = false;
  public color = '';
  public maxWidth = 'auto';
  public maintainAspectRatio = true;
  public proportion = 38;
  public slideHeight = '200px';
  public slides = [
    ['assets/beforeandafter/DSC_0264.jpg'],
    ['assets/beforeandafter/DSC_0263.jpg'],
    ['assets/beforeandafter/image_square2.png'],
    ['assets/beforeandafter/tshirtfrontandback.' + 'png'],
  ];
  public overlayColor = 'rgba(9,9,9,0.25)';
  public hideOverlay = false;
  public useKeyboard = true;
  public useMouseWheel = false;
  public orientation: Orientation = 'ltr';
  public log: string[] = [];
}
