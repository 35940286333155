import { Component } from '@angular/core';

@Component({
  selector: 'app-maintenance-component',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css'],
})
export class MaintenanceComponent {
  defaultLanguage = false;

  setLanguage(value: any): any {
    this.defaultLanguage = value;
  }
}
