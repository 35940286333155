<div class="container">
  <div class="div-flex">
    <ul class="ul-flex">
      <li class="li-flex" *ngFor="let img of photos">
        <a [routerLink]="['/image', img.id]">
          <img class="image-container" src="{{img.url}}">
        </a>
      </li>
    </ul>
  </div>
</div>
