import { Component, HostListener, OnInit } from '@angular/core';
import {
  faFacebookSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-component',
  templateUrl: './footer-component.html',
  styleUrls: ['./footer-component.css'],
})
export class FooterComponent {
  public windowRes = window.innerWidth < 580;

  constructor(public router: Router) {}

  insta = faInstagramSquare;
  fb = faFacebookSquare;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.windowRes = window.innerWidth < 580;
  }

  openPage(page: string): void {
    switch (page) {
      case 'ig':
        window.open('https://www.instagram.com/lages_fugentechnik/', '_blank');
        return;
      case 'fb':
        window.open('https://www.facebook.com/ChristopheLages1991/', '_blank');
        return;
    }
  }
}
