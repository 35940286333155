import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-galery-component',
  templateUrl: './galery-component.html',
  styleUrls: ['./galery-component.css'],
})
export class GaleryComponent implements OnInit {
  constructor(private http: HttpClient) {}

  photos = [
    { id: 1, title: 'PhotoTitle', url: 'assets/produtos/logo1.jpg' },
    { id: 2, title: 'PhotoTitle', url: 'assets/produtos/logo2.jpg' },
    { id: 3, title: 'PhotoTitle', url: 'assets/produtos/logo3.png' },
    { id: 4, title: 'PhotoTitle', url: 'assets/produtos/logo4.png' },
    { id: 5, title: 'PhotoTitle', url: 'assets/produtos/logo5.png' },
    { id: 6, title: 'PhotoTitle', url: 'assets/produtos/logo1.jpg' },
    { id: 7, title: 'PhotoTitle', url: 'assets/produtos/logo2.jpg' },
    { id: 8, title: 'PhotoTitle', url: 'assets/produtos/logo3.png' },
    { id: 9, title: 'PhotoTitle', url: 'assets/produtos/logo4.png' },
  ];

  ngOnInit(): void {
    // this.photos = this.http.get('https://api.instagram.com/v1/tags/nofilter/media/recent?client=lages-fugentechnik').subscribe();
  }
}
