<div class="home-container">
  <div class="banner-container container-middle">
    <div class="container-right">
      <img class="image-container" src="../../../assets/logo_a.png" />
    </div>
  </div>
</div>
<section class="container-middle">
  <div class="section-container">
    <div class="section-header">
      <h1 class="section-title">{{ 'our_work' | translate }}</h1>
      <p class="section-description">
        {{ 'our_work_description' | translate }}
      </p>
    </div>
    <div class="card-container">
      <div class="card" *ngFor="let card of cards">
        <mat-card>
          <img class="image-container" src="{{card.imageUrl}}" />
          <mat-card-content class="card-content">
            <h5 class="card-title">{{card.title | translate}}</h5>
            <p class="card-description">{{card.description | translate}}</p>
            <mat-divider class="position-relative"></mat-divider>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<section class="container-footer">
  <div></div>
  <div class="client-container">
    <div class="container-middle">
      <div class="section-container">
        <div class="div-container">
          <div class="flex-container">
            <div
              class="justify-content"
              *ngFor="let item of items; let i = index"
            >
              <div class="image-container-half">
                <img class="left-side-container" src="{{item.imageUrl}}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
